const base64js = require('base64-js')
const vapidPublicKey = new Uint8Array(base64js.toByteArray(process.env.VAPID_PUBLIC_KEY))

if (navigator.serviceWorker) {
    navigator.serviceWorker.register('/serviceworker.js', { scope: './' })
        .then(function(reg) {
            console.log('[Companion]', 'Service worker registered!', reg);
        });
} else {
    console.error('Service worker is not supported in this browser');
}

navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
    serviceWorkerRegistration.pushManager
        .subscribe({
            userVisibleOnly: true,
            applicationServerKey: vapidPublicKey
        }).then((subscription) => {
            $.post("/subscribe", { subscription: subscription.toJSON() })
        });
});

// Let's check if the browser supports notifications
if (!("Notification" in window)) {
    console.error("This browser does not support desktop notification");
}

// Let's check whether notification permissions have already been granted
else if (Notification.permission === "granted") {
    console.log("Permission to receive notifications has been granted");
}

// Otherwise, we need to ask the user for permission
else if (Notification.permission !== 'denied') {
    Notification.requestPermission(function(permission) {
        if (permission === "granted") {
            console.log("Permission to receive notifications has been granted");
            getKeys();
        }
    });
}

function getKeys() {
    navigator.serviceWorker.register('/serviceworker.js', { scope: './' })
        .then((registration) => {
            registration.pushManager
                .subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: vapidPublicKey
                }).then((subscription) => {
                    console.log('subscribe and test push notification')
                    $.post("/subscribe", { subscription: subscription.toJSON() })
                        .then(() => $.post("/test"));
                });
        })
}