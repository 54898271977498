// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("packs/bootstrap.bundle.min");
require("packs/adminlte.min");
require('select2');
require('cocoon-js');
require('tree-multiselect')
require("chartkick")
require("chart.js")
require("packs/best_in_place")
require("packs/jquery.purr")

import '../../assets/javascripts/serviceworker-companion'
import '../../assets/javascripts/summernote.min.js'
import $ from 'jquery';
global.$ = jQuery;
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(document).on('turbolinks:load', function() {
    $('[data-toggle="tooltip"]').tooltip()
    $(".best_in_place").best_in_place();
    $('select.js-select').select2({});

    // for export report to excel 
    $("#export-report-btn").click(function(e){
        e.preventDefault();
        if ( $('form')[0].checkValidity() ) {
            let exportBtn = $(this);
            exportBtn.prop("disabled",true);
            let dw = window.open($(this).data('url')+'?'+$('form').serialize(), '_blank');
            $(dw.documnet).ready(function() { 
                exportBtn.prop("disabled",false);
            });
        }else{
            $('form')[0].reportValidity();
        }
    });
});